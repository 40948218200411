import { render, staticRenderFns } from "./tagResult.vue?vue&type=template&id=0e37475d&scoped=true&"
import script from "./tagResult.vue?vue&type=script&lang=js&"
export * from "./tagResult.vue?vue&type=script&lang=js&"
import style0 from "./tagResult.vue?vue&type=style&index=0&id=0e37475d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e37475d",
  null
  
)

export default component.exports