<template>
    <div class="video-cover">
        <div class="top">
            <ImgDecypt class="cover-img" :src="item.videoCover"/>
            <div class="video-info flex-between">
                <div class="left">
                    <span class="left-play"></span>{{item.watchTimes|watchCount}}
                </div>
                <div class="right">{{item.playTime|videotime}}</div>
            </div>
            <div class="header">
                <ImgDecypt :round="true" class="cover-header" :src="item.author&&item.author.avatarUrl"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            default:() => {
                return {}
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.video-cover{
    width:115.6px;
    margin-top:8px;
    margin-right:4px;
}
.video-cover:nth-child(3n){
    margin-right:0;
}
.top{
    font-size:0;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}
.top /deep/ .van-image__img{
    object-fit: cover !important;
}
.cover-img{
    width:100%;
    height:180px;
}
.video-info{
    position:absolute;
    bottom:0;
    width:100%;
    height:26px;
    align-items: center;
    background:linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,0.2));
    padding:0 6.3px;
    box-sizing: border-box;
    font-size:8px;
    color:#ffffff;
}
.left-play{
    display: inline-block;
    width:8.9px;
    height:9.4px;
    background:url('../../assets/png/play_icon.png')center center no-repeat;
    background-size:100%;
}
.header{
    position:absolute;
    top:8px;
    left:8px;
    width:24px;
    height:24px;
    border-radius: 50%;
    border:1px solid #F21313;
}
.cover-header{
    width:100%;
    height:100%;
}
</style>